:root {
    --primary: #22409a;
    --secondary: #49a1da;
    --tertiary: #00b9ad;
  }
  
.login-modal-title {
    color: var(--primary);
    font-weight: bold;
}
