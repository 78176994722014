:root {
    --primary: #22409a;
    --secondary: #49a1da;
    --tertiary: #00b9ad;
  }

.privacy-policy {
    margin-top: 4%;
    margin-left: 10%;
    margin-right: 10%;
}

.privacy-policy>h1 {
    color: var(--primary);
    text-align: left;
    font-size: 30px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.privacy-policy>p {
    color: var(--primary);
    white-space: pre-wrap;
    text-align: left; 
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.privacy-policy>h2 {
    color: var(--primary);
    text-align: left;
    font-size: 25px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}