:root {
  --primary: #22409a;
  --secondary: #49a1da;
  --tertiary: #00b9ad;
}

.cards {
  padding: 1rem;
  background: #fff;
}

h1 {
  text-align: center;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
  'Lucida Sans', Arial, sans-serif;
}

.cards_container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
}

.cards_wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards_items {
  margin-bottom: 24px;
  padding-left: 0;
}

.cards_item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.cards_item_link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
  padding-bottom: 8%;
}

.cards_item_title {
  position:relative;
  left: 20px;
  max-height: 60%;
}

.cards_item_title_text {
  color: var(--primary);
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
  'Lucida Sans', Arial, sans-serif;
}


.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}


.cards_item_img {
  position:relative;
  top: 20px;
  bottom: 0;
  left: 20%;
  display: block;
  width: 60%;
  max-width: 60%;
  height: 60%;
  max-height: 60%;
  object-fit:scale-down;
  transition: all 0.2s linear;
}


.cards_item_info {
  padding: 20px 30px 20px 20px;
}

.cards_item_info_text {
  color: var(--primary);
  font-size: 16px;
  line-height: 24px;
  font-weight: 100;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
  'Lucida Sans', Arial, sans-serif;
}

cards_learn_more_button {
position: relative;
}

.cards_button_container {
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}


@media only screen and (min-width: 1200px) {

}

@media only screen and (min-width: 940px) {
  .cards_items {
    display: flex;
  }


}

@media only screen and (max-width: 939px) {
  .cards_item {
    margin-bottom: 2rem;
  }

  .cards_item_info_text {
    font-size: 20px;
  }

  .cards_item_title_text {
    font-size: 24px;
  }
  
}

@media screen and (max-width: 768px) {

  .cards_item_info_text {
    font-size: 14px;
  }

  .cards_item_title_text {
    font-size: 18px;
  }
}