:root {
  --primary: #22409a;
  --secondary: #49a1da;
  --tertiary: #00b9ad;
}

.main-container {
  background: url('/src/images/mountainpersonfreedom.jpeg') center center/cover no-repeat;
  height: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.main-container>h1 {
  color: #fff;
  font-size: 40px;
  margin-left: 10%;
  margin-right: 10%;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.main-container>p {
  text-align: center;
  margin-top: 8px;
  color: #fff;
  font-size: 20px;
  margin-left: 15%;
  margin-right: 15%;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.main-btn {
  margin-top: 32px;
}

.main-btn .btn {
  margin: 6px;
}

.popover-basic {
  background-color: #fff;
}

.first_submit_button {
  padding-right: 10px;
}

.first_close_button {
  padding-left: 10px;
}

.second_submit_button {
  padding-right: 10px;
}

.second_skip_button {
  padding-left: 10px;
}

.second_footer {
  border: none;
}

.modal_footer {
  border: 0;
}

.main_title {
  color: var(--primary);
  padding-top: 60px;
  font-size: 40px;
  display: flex;
  justify-content: center;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
  'Lucida Sans', Arial, sans-serif;
}

.tagline {
  color: var(--primary);
  padding-top: 60px;
  font-size: 30px;
  margin-left: 10pt;
  margin-right: 10pt;
  display: flex;
  text-align: center;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
  'Lucida Sans', Arial, sans-serif;
}

@media screen and (max-width: 1016px) {
  .main_title {
    color: var(--primary);
    padding-top: 20px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  }
}

@media screen and (max-width: 960px) {
  .main-container {
    height: 70vh;
  }

  .main-container>h1 {
    font-size: 30px;
  
  }

  .main-container>p {
    font-size: 15px;
  }
}

@media screen and (max-width: 768px) {
  .main-container {
    height: 60vh;
  }

  .main-container>h1 {
    font-size: 25px;

  }

  .main-container>p {
    font-size: 15px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}