:root {
  --primary: #22409a;
  --secondary: #49a1da;
  --tertiary: #00b9ad;
}

.btn-layout {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
  'Lucida Sans', Arial, sans-serif;
}

.btn--primary {
  background-color: var(--primary);
  color: #fff;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: var(--primary);
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--primary:hover {
  transition: all 0.3s ease-out;
  background: var(--secondary);
  border-color: var(--secondary);
  color: #fff;
  transition: 250ms;
}

.btn--outline:hover {
  transition: all 0.3s ease-out;
  background: var(--secondary);
  transition: 250ms;
}

@media screen and (max-width: 1155px) {
  .btn--card {
    font-size: 16px;
  }
}

@media screen and (min-width: 1023px) and (max-width: 1065px) {
  .btn--card {
    font-size: 14px;
  }
}

@media screen and (max-width: 1023px) {
  .btn--card {
    font-size: 18px;
  }
}