:root {
  --primary: #22409a;
  --secondary: #49a1da;
  --tertiary: #00b9ad;
}

.navbar-layout {
  background: #fff;
  height: 80px;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.parent>* {
  margin: 10px;
  margin-left: 0;
}

.nav-right-side {
  display: flex;
  align-items: center;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 100%;
}

.navbar_logo_img {
    width: 200px;
    margin-left: 10px;
    margin-right: 10px;
    object-fit:scale-down;
    transition: all 0.2s linear;
}

.navbar-tagline {
  color: var(--primary);
  cursor: pointer;
  text-decoration: none;
  font-size: 14pt;
  margin-left: 10px;
  font-style: italic;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.bullet-point {
  color: var(--primary);
  font-size: 1rem;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.navbar-logo-container {
  display: flex;
  align-items: center;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  /* column-gap: 30px; */
  margin-right: 20px;
  list-style: none;
  text-align: center;
  justify-content: end;
  margin-bottom: 0;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: var(--primary);
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.nav-links:hover {
  border-bottom: 4px solid var(--primary);
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: var(--primary);
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 801px) {
  /* .navbar-layout {
    height: 85px;
  } */
  .nav-right-side {
    justify-content: end;
  }

  /* .navbar-logo-container {
  margin-top: 5pt;
  }

  .navbar-tagline {
    font-size: 10pt;
  } */
  .nav-menu {
    width: 0;
  }
}

@media screen and (max-width: 1016px) {
  /* .navbar-logo-container {
    display: block;
  } */
}