:root {
  --primary: #22409a;
  --secondary: #49a1da;
  --tertiary: #00b9ad;
}


  .team-container {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 4%;
    color: var(--primary);
  }

  .team-container>h1 {
    text-align: left;
    font-size: 30px;
    margin-bottom: 20px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  }
  .bio-container {
    display: flex;
    column-gap: 40px;
    margin-bottom: 40px;
  }
 
  .bio-image {
    position:relative;
    display: block;
    object-fit:scale-down;
    transition: all 0.2s linear;
  }

  .title-container > h2 {
    margin-bottom: 0;
    font-size: 22px;
    font-weight: bold;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  }

  .title-container > h3 {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: bold;
    font-style: italic;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  }

  .title-container > h4 {
    margin-bottom: 0;
    font-size: 18px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  }

  .summary-container > p {
    font-size: 18px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  }

  @media only screen and (max-width: 940px) {
    .bio-container {
      display:block;
      align-items: center;
      justify-content: center;

    }

    .image-title-container {
      justify-content: center;
      align-items: center;
      
    }

  .team-container {
    align-items: center;
    justify-content: center;
  }
  
  .title-container {
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }
  }