:root {
    --primary: #22409a;
    --secondary: #49a1da;
    --tertiary: #00b9ad;
  }


.fsmodal-header {
    justify-content: center;
}


.modal-welcome-message {
    text-align: center;
    color: var(--primary);
}

.mb-3 {
    color: var(--primary);
}

form .form-control::-webkit-input-placeholder { 
    color: var(--primary);
    opacity: 0.6; }  /* WebKit, Blink, Edge */
form .form-control:-moz-placeholder { 
    color: var(--primary);
    opacity: 0.6; }  /* Mozilla Firefox 4 to 18 */
form .form-control::-moz-placeholder {
    color: var(--primary);
    opacity: 0.6; }  /* Mozilla Firefox 19+ */
form .form-control:-ms-input-placeholder { 
    color: var(--primary);
    opacity: 0.6;}  /* Internet Explorer 10-11 */
form .form-control::-ms-input-placeholder { 
    color: var(--primary);
    opacity: 0.6;}
form .form-control::placeholder { 
    color: var(--primary);
    opacity: 0.6;} /* Chrome */

.form-text {
    --bs-secondary-color: var(--primary);
}

.form-check-input {
    --bs-border-color: var(--primary);
}
.form-check-input:checked {
    background-color: var(--primary);
    border-color: var(--primary);
}
.form-control {
    --bs-body-color: var(--primary);
    --bs-border-color: var(--primary);
}
.required-text {
    color: red;
    padding-left: 5px;
}

.header-text {
    display: inline;
    align-items: center;
    color: var(--primary);
}
.fsmodal-title {
    font-size: 26px;
    text-align: center;
    padding-bottom: 8px;
}

.fin-details {
    color: var(--primary);
}

.footer-buttons {
    display: flex;
    gap: 10px
}

.age-certify {
    color: var(--primary);
    font-size: 12px;
}

.submit-confirm {
    font-size: 20px;
    color: var(--primary);
}